<template>
    <div class="card">
        <div class="card-body">
            <div class="row justify-content-end">
                <div class="col">
                    Create online website
                </div>
                <div class="col-auto ml-auto" v-if="!storeInformation.website">
                    <button class="btn btn-info" @click="fnApiCreateWebsite()" :disabled="loading.createWebsite">
                        Crete Website
                    </button>
                </div>
                <div class="col-auto" v-show="storeInformation.website">
                    <router-link class="btn btn-success" :to="'/web-editor/' + storeInformation.id">
                        Edit
                    </router-link>
                </div>
                <div class="col-auto ml-auto" v-show="storeInformation.website">
                    <button class="btn btn-danger" @click="fnApiDeleteWebsite()" :disabled="loading.createWebsite">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        computed: {
            ...mapState('EcartStore', ['storeInformation', 'loading']),
        },
        methods: {
            ...mapActions('EcartStore', ['fnApiCreateWebsite', 'fnApiDeleteWebsite'])
        }
    }
</script>

<style>

</style>